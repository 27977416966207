<template>
  <el-alert class="alert-mobile" type="warning" center v-if="alertVisible" v-bind="$attrs" v-on="$listeners">
    <template>是否切换为移动端网页？<button @click="viewMobile">立即切换</button> </template>
  </el-alert>
</template>
<script>
import { setViewDesktop } from '@/utils/cookies';
export default {
  name: 'AlertMobile',
  computed: {
    alertVisible() {
      return /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/gi.test(navigator.userAgent);
    },
  },
  methods: {
    /**
     * 访问移动端
     */
    viewMobile() {
      setViewDesktop();
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-mobile {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  padding: 10px;
  ::v-deep {
    .el-alert__description {
      font-size: 40px;
      line-height: 1.25;
      button {
        font-size: inherit;
        text-decoration: underline;
      }
    }
    .el-alert__closebtn {
      font-size: 35px;
    }
  }
}
</style>
