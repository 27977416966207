import { saveTokenToCookie } from '@/utils/cookies';

const commonStore = {
  state: {
    // 登录凭证
    _token: '',
    // 用户信息加载状态
    _userLoading: true,
    // 用户信息
    _user: {},
    // 位置信息
    _position: {
      latitude: 43.65332780000001,
      longitude: -79.38403079999999,
    },
    // 登录对话框显示状态
    _loginDialogStatus: {
      visible: false,
      type: 'account',
    },
  },
  getters: {
    // 用户登录凭证
    token(state) {
      return state._token;
    },
    // 用户信息加载状态
    userLoading(state) {
      return state._userLoading;
    },
    // 用户信息
    user(state) {
      return state._user;
    },
    // 用户是否登录
    isLogin(state) {
      return state._user.id ? true : false;
    },
    // 用户昵称
    nickname(state) {
      return JSON.stringify(state._user) === '{}' ? '' : state._user.nickname;
    },
    // 用户头像
    avatar(state) {
      return JSON.stringify(state._user) === '{}' ? [] : state._user.avatar;
    },
    // 用户手机号码是否认证
    phoneAuth(state) {
      return state._user && state._user.phoneAuth == 1;
    },
    // 位置信息
    position(state) {
      return state._position;
    },
    // 登录对话框显示状态
    loginDialogStatus(state) {
      return state._loginDialogStatus;
    },
  },
  mutations: {
    // 设置 token
    setToken(state, token) {
      state._token = token;
    },
    // 设置用户信息加载状态
    setUserLoading(state, loading) {
      state._userLoading = loading;
    },
    // 设置用户信息
    setUser(state, user) {
      state._user = user;
    },
    // 设置位置
    setPosition(state, position) {
      state._position = position;
    },
    // 显示登录对话框
    showLoginDialog(state, type = 'account') {
      state._loginDialogStatus.type = type;
      state._loginDialogStatus.visible = true;
    },
    // 设置登录对话框显示状态
    setLoginDialogVisible(state, status) {
      state._loginDialogStatus.visible = status;
    },
    // 设置登录对话框类型
    setLoginType(state, type) {
      state._loginDialogStatus.type = type;
    },
  },
  actions: {
    login(context, { token, user }) {
      context.commit('setToken', token);
      context.commit('setUser', user);
      saveTokenToCookie(token);
    },
    // 获取位置
    getLocation({ commit }) {
      if (!navigator.geolocation) return;
      navigator.geolocation.getCurrentPosition(position => commit('setPosition', position.coords));
    },
  },
};

export { commonStore };
