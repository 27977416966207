<template>
  <el-dialog class="login-dialog" v-bind="$attrs" v-on="$listeners" :title="title" :width="width">
    <YorkbbsLogin
      :type="type"
      isDialog
      @qrcodeLogin="$emit('update:type', 'qrcode')"
      @accountLogin="$emit('update:type', 'account')"
      @phoneLogin="$emit('update:type', 'phone')"
      @accountLoginSuccess="onAccountLoginSuccess"
      @phoneLoginSuccess="onPhoneLoginSuccess"
      @qrcodeLoginSuccess="onQrcodeLoginSuccess"
    />
  </el-dialog>
</template>
<script>
import YorkbbsLogin from './login.vue';
export default {
  name: 'DialogLogin',
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 宽度
    width: {
      type: String,
      default: '470px',
    },
    // 登录类型
    type: {
      type: String,
      default: 'account',
      validator: function(t) {
        return ['account', 'phone', 'qrcode'].includes(t);
      },
    },
  },
  components: { YorkbbsLogin },
  methods: {
    /**
     * 账号密码登录成功
     */
    onAccountLoginSuccess() {
      this.$emit('loginSuccess');
      this.$emit('accountLoginSuccess');
      this.$emit('update:visible', false);
    },
    /**
     * 手机验证登录成功
     */
    onPhoneLoginSuccess() {
      this.$emit('loginSuccess');
      this.$emit('phoneLoginSuccess');
      this.$emit('update:visible', false);
    },
    /**
     * 扫码登录成功
     */
    onQrcodeLoginSuccess() {
      this.$emit('loginSuccess');
      this.$emit('qrcodeLoginSuccess');
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.login-dialog {
  ::v-deep {
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog {
      border-radius: 6px;
    }
  }
}
</style>
