<template>
  <!-- 包含手机号前缀版本 -->
  <el-form-item class="form-item-phone" v-bind="$attrs" v-if="showPhonePrefix">
    <el-input
      class="input-phone"
      clearable
      :maxlength="maxLength"
      v-model.trim="formatPhone"
      @input="onFormatPhoneChange"
      v-bind="$attrs"
      v-on="$listeners"
      autofocus
    >
      <el-select v-model="phonePrefixIndex" slot="prepend" :placeholder="prefix" @change="onPhonePrefixChange">
        <el-option
          class="flex items-center justify-between text-[12px] leading-[20px]"
          v-for="(item, i) in phonePrefixs"
          :key="i"
          :label="item.code"
          :value="i"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.code }}</span>
        </el-option>
      </el-select>
    </el-input>
    <slot></slot>
  </el-form-item>
  <!-- 不包含手机号前缀版本 -->
  <el-form-item v-bind="$attrs" v-else>
    <el-input
      clearable
      :maxlength="maxLength"
      v-model.trim="formatPhone"
      @input="onFormatPhoneChange"
      v-bind="$attrs"
      v-on="$listeners"
    ></el-input>
  </el-form-item>
</template>
<script>
export default {
  name: 'formItemPhone',
  props: {
    // 手机号前缀
    phonePrefix: {
      type: String,
    },
    // 手机号
    phone: {
      type: String,
    },
    // 显示手机号前缀
    showPhonePrefix: {
      type: Boolean,
      default: true,
    },
    // 是否获取国家代码
    isNeedCountryCode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 手机号前缀列表
      phonePrefixs: [{ name: '加拿大/美国', code: '+1' }],
      // 手机号前缀索引
      phonePrefixIndex: undefined,
      // 格式化后的手机号码
      formatPhone: '',
    };
  },
  computed: {
    prefix() {
      return this.phonePrefixIndex == null ? '+1' : this.phonePrefixs[this.phonePrefixIndex].code;
    },
    maxLength() {
      return !this.showPhonePrefix || this.prefix == '+86' ? 13 : 12;
    },
  },
  created() {
    this.formatPhone = this.phone;
    if (this.showPhonePrefix && this.isNeedCountryCode) {
      this.getPhonePrefixs();
    }
  },
  methods: {
    /**
     * 获取国家代码
     */
    async getPhonePrefixs() {
      const { data } = await this.$axios.post('/user/v1/get/system/country_code');
      const phonePrefixs = [];
      data.forEach(item => {
        phonePrefixs.push(...item.list);
      });
      this.phonePrefixs = phonePrefixs;
    },
    /**
     * 手机号码前缀发生变化
     */
    onPhonePrefixChange() {
      this.onFormatPhoneChange(this.formatPhone.substr(0, this.maxLength).replace(/\s/g, ''));
      this.$emit('update:phonePrefix', this.prefix);
    },
    /**
     * 手机号码变化时
     */
    onFormatPhoneChange(newVal) {
      /* newVal = newVal.replace(/[^\d\s]/g, '');
      const regex = this.prefix == '+86' ? /((\d{3})\s?)((\d{0,4})\s?)?(\d+)?/ : /((\d{3})\s?)((\d{0,3})\s?)?(\d+)?/;

      newVal = newVal.replace(regex, (match, p1, p2, p3, p4, p5) => {
        if (p5) return `${p2} ${p4} ${p5}`;
        if (p4) return `${p2} ${p4}`;
        return `${p2}`;
      });
      this.formatPhone = newVal;
      this.$emit('update:phone', newVal.replace(/\s/g, '')); */

      const nums = newVal.match(/\d/g);
      const numstr = nums == null ? '' : nums.join('');
      const regex = this.prefix == '+86' ? /(\d{3})(\d{0,4})?(\d+)?/ : /(\d{3})(\d{0,3})?(\d+)?/;
      newVal = numstr.replace(regex, (match, p1, p2, p3) => {
        if (p3) return `${p1} ${p2} ${p3}`;
        if (p2) return `${p1} ${p2}`;
        return `${p1}`;
      });
      this.formatPhone = newVal;

      this.$emit('update:phone', newVal.replace(/\s/g, ''));
    },
  },
};
</script>
<style lang="scss">
// 带国家代码的手机号码输入框样式

.form-item-phone {
  .el-input-group {
    border: 1px solid #cccccc;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    .el-input-group__prepend {
      border: 0 none;
    }
    .el-input__inner {
      border: 0 none;
    }
  }
  .input-phone {
    > .el-input__inner {
      border-left-width: 0;
    }
    .el-input-group__prepend {
      background-color: #fff;
      color: #111;
      .el-input__inner {
        &::placeholder {
          color: #111;
        }
      }
    }
    .el-input--suffix {
      &::after {
        content: '';
        width: 1px;
        height: 50%;
        background-color: #dcdfe6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .el-input__inner {
        padding-right: 14px;
        padding-left: 10px;
        color: #111;
      }
    }
    .el-select {
      .el-input {
        width: 65px;
      }
    }
  }
  &.is-error {
    .el-input-group {
      border-color: #e43a3a;
    }
  }
  &:not(.is-error) {
    .el-input-group {
      &:active,
      &:focus,
      &:hover {
        border-color: #666;
      }
    }
  }
}
</style>
