import { deleteTokenFromCookie, getTokenFromCookie } from '@/utils/cookies';

export const getUserMixin = {
  created() {
    this.getUserInfo();
  },
  methods: {
    /**
     * 获取用户信息
     */
    async getUserInfo() {
      const token = getTokenFromCookie();
      if (!token) return;
      this.$store.commit('setToken', token);

      this.$store.commit('setUserLoading', true);
      const { code, msg, data } = await this.$axios.post('/user/v1/info/by/token').finally(() => {
        
        this.$store.commit('setUserLoading', false);
      });
      if (code === 0) {
        this.$store.commit('setUser', data.user);
        if( window.ssq){
          window.ssq.push('setLoginInfo', {
            user_id: data.user.id, // 必填，加密后的用户 id
            user_name: data.user.username, // 必填，用户名
            language: 'en', // 插件语言
            phone: data.user.phonePrefix+data.user.phone, // 手机（注意！带上手机区号）
            email: data.user.email, // 邮箱
            description: data.user.username, // 描述
          });
        }
        
      } else {
        deleteTokenFromCookie();
        this.$store.commit('setToken', '');
        this.$store.commit('setUser', {});
        this.$error(msg);
      }
    },
  },
};
