<template>
  <el-input class="input__password" :type="inputType" v-bind="$attrs" v-on="$listeners">
    <template slot="prefix"><slot name="prefix"></slot></template>
    <template slot="prepend"><slot name="prepend"></slot></template>
    <template slot="append"><slot name="append"></slot></template>
    <template slot="suffix">
      <i class="suffix-icon el-icon-view" v-if="inputType == 'text'" @click="hidePassword"></i>
      <i class="suffix-icon yk-eye-close" v-if="inputType == 'password'" @click="showPassword"></i>
    </template>
  </el-input>
</template>
<script>
export default {
  name: 'InputPassword',
  data() {
    return {
      // 输入框类型
      inputType: 'password',
    };
  },
  methods: {
    /**
     * 显示密码
     */
    showPassword() {
      this.inputType = 'text';
    },
    /**
     * 隐藏密码
     */
    hidePassword() {
      this.inputType = 'password';
    },
  },
};
</script>
<style lang="scss" scoped>
.input__password {
  ::v-deep {
    .el-input__inner {
      padding-right: 60px;
    }
    .suffix-icon {
      display: inline-block;
      height: 100%;
      width: 25px;
      text-align: center;
      transition: all 0.3s;
      cursor: pointer;
      color: #c0c4cc;
      &:after {
        content: '';
        height: 100%;
        width: 0;
        display: inline-block;
        vertical-align: middle;
      }
      &:hover {
        color: #909399;
      }
    }
    .yk-eye-close {
      font-size: 14px;
    }
    .el-input__suffix {
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.el-input-group--append {
    ::v-deep {
      .el-input__suffix {
        right: 8px;
      }
    }
  }
}
</style>
