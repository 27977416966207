<template>
  <div class="login-tabs">
    <button class="login-tab" :class="{ 'is-active': loginType == 'account' }" @click="onAccountClick">
      账号密码登录
    </button>
    <button class="login-tab" :class="{ 'is-active': loginType == 'phone' }" @click="onPhoneClick">
      手机验证登录
    </button>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  props: {
    isDialog: Boolean,
    type: String,
  },
  computed: {
    loginType() {
      if (this.isDialog) return this.type;

      const { login_type } = this.$route.query;
      if (login_type == 'account') return 'account';
      if (login_type == 'phone') return 'phone';
      if (login_type == 'qrcode') return 'qrcode';
      return 'account';
    },
  },
  methods: {
    onAccountClick() {
      if (this.isDialog) {
        this.$emit('accountLogin');
      } else {
        this.$router.push({ query: { ...this.$route.query, login_type: 'account' } });
      }
    },
    onPhoneClick() {
      if (this.isDialog) {
        this.$emit('phoneLogin');
      } else {
        this.$router.push({ query: { ...this.$route.query, login_type: 'phone' } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-tabs {
  margin-bottom: 20px;
}
.login-tab {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #333;
  &:not(:first-child) {
    margin-left: 25px;
  }
  &.is-active {
    color: #e4393c;
    font-weight: 600;
  }
}
</style>
