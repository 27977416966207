import Vue from 'vue';
import '@/assets/css/element-ui.scss';
import ELEMENT from 'element-ui';

export function successMessage(msg) {
  ELEMENT.Message({ type: 'success', message: msg, duration: 2000, customClass: 'max-zindex' });
}

export function errorMessage(msg) {
  ELEMENT.Message({
    type: 'error',
    message: msg,
    duration: 4000,
    dangerouslyUseHTMLString: true,
    customClass: 'max-zindex',
  });
}

export function infoMessage(msg) {
  ELEMENT.Message({ type: 'info', message: msg, duration: 4000, customClass: 'max-zindex' });
}

export function warningMessage(msg) {
  ELEMENT.Message({ type: 'warning', message: msg, duration: 4000, customClass: 'max-zindex' });
}

Vue.prototype.$success = successMessage;
Vue.prototype.$error = errorMessage;
Vue.prototype.$info = infoMessage;
Vue.prototype.$warning = warningMessage;

Vue.use(ELEMENT);
