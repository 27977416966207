import Vue from 'vue';
import cookies from 'vue-cookies';

cookies.config('7d');

// 域名
const { hostname } = window.location;
// 顶级域名
const topDomain = `.${hostname
  .split('.')
  .slice(-2)
  .join('.')}`;
// 是否是开发模式
const isDevelopment = /^((\d+\.\d+\.\d+\.\d+)|localhost)/.test(hostname);

/**
 * 保存 token 到 cookie
 */
export function saveTokenToCookie(token) {
  if (isDevelopment) {
    cookies.set('token', token, -1, '/', hostname);
  } else {
    cookies.set('token', token, -1, '/', topDomain);
  }
}

/**
 * 从 cookie 中获取 token
 */
export function getTokenFromCookie() {
  return cookies.get('token');
}

/**
 * 删除 cookie 中的 token
 */
export function deleteTokenFromCookie() {
  if (isDevelopment) {
    cookies.remove('token', '/', hostname);
  } else {
    cookies.remove('token', '/', topDomain);
  }
}

/**
 * 更新指定系统、指定页面、指定广告类型的广告展示次数
 * @param {string} adType 广告类型（允许自定义） inflow 信息流广告；banner 图片广告
 * @param {string} system 系统
 * @param {number} zoomId 页面ID
 * @returns 展示次数
 */
export const updateAdvTimes = (adType, system, zoomId) => {
  const k = 'adv_display_times',
    v = cookies.get(k) || {};

  const key = `${adType}_${system}_${zoomId}`,
    times = (v[key] = (v[key] || Math.floor(Date.now()/1000)) + 1);

  cookies.set(k, v);

  return times;
};

export const setViewDesktop = () => {
  if (isDevelopment) {
    cookies.remove('view_platform', '/', hostname);
  } else {
    cookies.remove('view_platform', '/', topDomain);
  }
};

Vue.prototype.$cookies = cookies;

export { cookies };
