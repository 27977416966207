<template>
  <div
    class="image-fit"
    :class="{ animation,aspect }"
    :style="{ width, height }"
    :target="target"
    :to="tag === 'routerLink' && link"
    :href="link"
   
    :is="tag"
  >
  <slot></slot>
    <el-image
      ref="elImage"
      v-bind="$attrs"
      v-on="$listeners"
      :src="src"
      :fit="fit"
      :lazy="lazy"
      :scroll-container="scrollContainer"
      :preview-src-list="previewSrcList"
      :z-index="zIndex"
      :style="{ borderRadius }"
      :alt="alt"
    >
    
      <slot name="placeholder" slot="placeholder">
        <div class="image-fit__placeholder"></div>
      </slot>
      <slot name="error" slot="error">
        <div class="image-fit__error"></div>
      </slot>
    </el-image>
  </div>
</template>
<script>
import {linkNeedAddRel} from '@/utils/utils'
export default {
  name: 'CoverImage',
  props: {
    // 宽度
    width: String,
    // 图片高度
    height: String,
     // 是否保持 4:3 宽高比
    aspect: Boolean,
    // 图片链接
    src: String,
    // 外链
    link: String,
    // 打开窗口方式
    target: String,
    // 是否开启动画
    animation: Boolean,
    // 圆角
    borderRadius: String,
    // 图片适应容器框的方式
    fit: { type: String, default: 'cover' },
    // 是否开启懒加载
    lazy: { type: Boolean, default: false },
    // 开启懒加载后，监听 scroll 事件的容器
    scrollContainer: { default: () => window },
    // 图片预览列表
    previewSrcList: Array,
    // 设置图片预览的 z-index
    zIndex: { type: Number, default: 2000 },
    //原生属性alt
    alt:String
  },
  computed: {
    // 外层容器标签
    tag() {
      if (!this.link) return 'div';
      if (this.target || /^(\/\/|http|javascript)/i.test(this.link)) return 'a';
      return 'routerLink';
    },
  },
  methods:{
    linkNeedAddRel(data){
      return linkNeedAddRel(data);
    },
  }
};
</script>
<style lang="scss">
.image-fit {
  display: block;
  font-size: 0;
  position: relative;
  &.animation {
    .el-image__inner {
      transition: transform 0.3s linear;
    }
    &:hover {
      .el-image__inner {
        transform: scale(1.1);
      }
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  &.aspect {
    &::after {
      content: '';
      display: inline-block;
      padding-bottom: 75%;
    }
    .el-image {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}
.image-fit__placeholder,
.image-fit__error {
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/common/default_img.png') no-repeat center / contain;
  background-color: rgb(242, 242, 242);
}
</style>
