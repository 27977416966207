<template>
  <div class="qrcode-container">
    <button class="account-link" @click="onAccountClick">
      <img src="./images/pc.png" />
    </button>
    <div class="qrcode-title">扫码登录</div>
    <div class="qrcode-desc">
      <span>请使用</span>
      <span class="text-primary mx-[6px]">约克论坛APP</span>
      <span>扫码登录</span>
    </div>

    <div class="qrcode-wrapper">
      <div class="qrcode" v-loading="loading">
        <canvas ref="qrCode"></canvas>
        <div class="qrcode-mask" v-show="isExpired" @click="getQrcodeParams">
          <div>
            <div><i class="yk-refresh"></i></div>
            <div class="msg">二维码已失效</div>
          </div>
        </div>

        <div class="qrcode-mask" v-show="!isExpired && isScaned">
          <div>
            <i class="yk-check-active"></i>
            <div class="msg">扫描成功！<br />等待手机端确认</div>
          </div>
        </div>
      </div>
      <img class="qrcode-example" src="./images/example.png" />
    </div>
    <div class="qrcode-tip">最新版约克论坛App -「我的」左上角 - 扫一扫</div>
  </div>
</template>
<script>
import QRCode from 'qrcode';
import { hostBuild } from '@/utils/utils';
export default {
  name: 'LoginQrcode',
  props: {
    // 是否是对话框
    isDialog: Boolean,
    // 类型
    type: String,
  },
  data() {
    return {
      // 二维码加载状态
      loading: false,
      // 二维码参数
      seqId: '',
      // 二维码是否过期
      isExpired: false,
      // 二维码是否已扫描但未确认
      isScaned: false,
      // 计时器对象
      timer: null,
    };
  },
  created() {
    this.getQrcodeParams();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    /**
     * 等待用户扫码登录
     */
    async awaitUserScan() {
      this.timer = setInterval(async () => {
        const { data } = await this.$axios.post('/user/v1/scan/login', {
          seqId: this.seqId,
        });

        const { result, token, user } = data;

        if (result === -1001) {
          // 二维码已过期
          this.isExpired = true;
          clearInterval(this.timer);
        }
        if (result === 99) {
          // 扫码未确认
          this.isScaned = true;
        }
        if (result === 98) {
          // 未扫码
        }
        if (result === 100) {
          // 成功拿到用户数据
          this.$store.dispatch('login', { token, user });

          clearInterval(this.timer);

          if (this.isDialog) {
            return this.$emit('qrcodeLoginSuccess');
          }

          if (this.$route.query.redirect) {
            location.replace(this.$route.query.redirect);
          } else {
            location.replace(hostBuild('/', 'www'));
          }
        }
      }, 3000);
    },
    /**
     * 生成二维码
     */
    createQrcodeImage(text) {
      QRCode.toCanvas(this.$refs.qrCode, text, { width: 150, margin: 0 });
    },
    /**
     * 获取二维码参数
     */
    async getQrcodeParams() {
      this.loading = true;
      const { data } = await this.$axios.post('/user/v1/scan/login/get/data').finally(() => {
        this.loading = false;
      });
      this.seqId = data.seqId;

      await this.$nextTick();
      this.createQrcodeImage(JSON.stringify(data));
      this.isExpired = false;
      clearInterval(this.timer);
      this.awaitUserScan();
    },

    onAccountClick() {
      if (this.isDialog) {
        this.$emit('accountLogin');
      } else {
        this.$router.push({ query: { ...this.$route.query, login_type: 'account' } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qrcode-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 30px 80px 60px;
  position: relative;
}

.qrcode-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #111;
  text-align: center;
}

.qrcode-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #333;
  margin-top: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.qrcode-wrapper {
  display: flex;
}

.qrcode {
  position: relative;
  width: 170px;
  height: 170px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 7px;
}

.qrcode-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  .yk-refresh {
    color: rgba(255, 255, 255, 0.8);
    font-size: 40px;
  }
  .yk-check-active {
    color: #52c41a;
    font-size: 40px;
  }
  .msg {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    margin-top: 16px;
  }
}

.qrcode-example {
  width: 130px;
  margin-left: 10px;
}

.qrcode-tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #999;
  margin-top: 10px;
  text-align: center;
}

.account-link {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
