import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return originalPush.call(this, to).catch(e => {
    if (e.name == 'NavigationDuplicated') location.reload(false);
  });
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(to) {
  return originalReplace.call(this, to).catch(e => {
    if (e.name == 'NavigationDuplicated') location.reload(false);
  });
};

function scrollBehavior(to, from, savedPosition) {
  // if (savedPosition) {
  //   return savedPosition;
  // } else {
  //   return { x: 0, y: 0 };
  // }
  return { x: 0, y: 0 };
}

export { Router, scrollBehavior };
