<template>
  <div class="login">
    <!-- 账号邮箱登录 -->
    <YorkbbsAccountLogin
      :isDialog="isDialog"
      :type="type"
      v-on="$listeners"
      v-if="loginType == 'account'"
    ></YorkbbsAccountLogin>

    <!-- 手机号登录 -->
    <YorkbbsPhoneLogin
      :isDialog="isDialog"
      :type="type"
      v-on="$listeners"
      v-if="loginType == 'phone'"
    ></YorkbbsPhoneLogin>

    <!-- 扫码登录 -->
    <YorkbbsQrcodeLogin
      :isDialog="isDialog"
      :type="type"
      v-on="$listeners"
      v-if="loginType == 'qrcode'"
    ></YorkbbsQrcodeLogin>
  </div>
</template>
<script>
import YorkbbsAccountLogin from './login-account.vue';
import YorkbbsPhoneLogin from './login-phone.vue';
import YorkbbsQrcodeLogin from './login-qrcode.vue';

export default {
  name: 'Login',
  // inject: ['getLoginType'],
  props: {
    type: String,
    isDialog: Boolean,
  },
  computed: {
    loginType() {
      if (this.isDialog) return this.type;

      const { login_type } = this.$route.query;
      if (login_type == 'account') return 'account';
      if (login_type == 'phone') return 'phone';
      if (login_type == 'qrcode') return 'qrcode';
      return 'account';
    },
  },
  components: {
    YorkbbsAccountLogin,
    YorkbbsPhoneLogin,
    YorkbbsQrcodeLogin,
  },
};
</script>
