import { updateAdvTimes } from './cookies';

const isSupportWebp = (() => {
  try {
    return (
      document
        .createElement('canvas')
        .toDataURL('image/webp')
        .indexOf('data:image/webp') == 0
    );
  } catch (err) {
    return false;
  }
})();

// 小于10补0
export function fillZero(num) {
  return num < 10 ? '0' + num : '' + num;
}

// 日期时间格式化
export function timeFormat(timestamp, formater) {
  const date = timestamp ? new Date(timestamp) : new Date();
  const formatMap = {
    'y+': date.getFullYear(),
    'm+': fillZero(date.getMonth() + 1),
    'd+': fillZero(date.getDate()),
    'h+': fillZero(date.getHours()),
    'i+': fillZero(date.getMinutes()),
    's+': fillZero(date.getSeconds()),
  };
  formater = formater ? formater.toLowerCase() : 'y-m-d h:i:s';
  for (const item in formatMap) formater = formater.replace(new RegExp(item), formatMap[item]);
  return formater;
}



// 日期时间转文字描述
export function timeToStr(data) {
  const create = new Date(data).getTime() / 1000;
  const nowTime = new Date().getTime() / 1000;
  const inter = nowTime - create;
  if (inter < 60) return '刚刚';
  if (inter < 3600) return Math.floor(inter / 60) + '分钟前';
  if (inter < 24 * 3600) return Math.floor(inter / 3600) + '小时前';
  if (inter < 30 * 24 * 3600) return Math.floor(inter / (24 * 3600)) + '天前';
  return timeFormat(data, 'YYYY-MM-DD');
}

// 数字格式化 加,
export const numberFormat = num => (num ? num.toLocaleString('en-US') : '0');

// 距离格式化
export function distanceFormat(distance) {
  if (distance === undefined) return '';
  distance = parseFloat(distance);
  if (distance < 10000) return distance.toFixed(0);
  return (distance / 10000).toFixed(1) + '万';
}

// 跨域名跳转
export const hostBuild = (data, host) => {
  /**
   * 该过滤器只在跨域名的时候使用
   * 需要传入二级域名 例如 car
   * 使用a 标签进行渲染
   * 返回的是 一个绝对路径
   */
  let hostName = '';
  if( ['sa','cms','police'].includes(host)){
      //管理端
      if( window.location.hostname.indexOf('0') != -1 ){
          //测试环境
          hostName = 'yorktest.xyz';
      }else{

          hostName = 'guanli.info';
      }
  }else{
      //客户端
      if( window.location.hostname.indexOf('0') != -1 ){
          //测试环境
          hostName = 'yorktest.xyz';
      }else{

          hostName = 'yorkbbs.ca';
      }
      
  }
  
  const reg = /[^.\s]+\.([^.\s]+)$/g;
  const regResult = reg.exec(hostName);
  
  if (regResult === null) return 'javascript:alert("测试环境不能跳转");';
  if (host === undefined || host.length === 0) return 'javascript:alert("正在开发中...");';
  const isZero = window.location.hostname.indexOf('0')  != -1 ? '0' : '';
  if(host == 'crm'){
      return '//'  + isZero + host +'.' + regResult[0] + data; 
  }else{

      return '//' + host + isZero + '.' + regResult[0] + data;
  }
};

// 金钱格式化 分为单位
export const moneyFormat = cent => Number(`${Math.round(cent)}e-2`).toLocaleString('en-US');

// 标题格式化
export function titleFormat(title, len = 40) {
  if (!title) return '';
  let titleLen = 0;
  for (let i = 0; i < title.length; i++) {
    const charCodeAt = title[i].charCodeAt(i);
    if (charCodeAt >= 0 && charCodeAt <= 128) {
      // 非中文按一个长度计算
      titleLen += 1;
    } else {
      // 中文按两个长度计算
      titleLen += 2;
    }
  }
  return titleLen > len ? title.substring(0, len) + '...' : title;
}

// 手机号码格式化
export function phoneFormat(phoneNum) {
  if (typeof phoneNum !== 'string') return '';
  phoneNum = phoneNum.replace(/[^\d]/g, '');
  if (phoneNum.length < 10) return phoneNum;
  const regex = phoneNum.length == 11 ? /(\d{3})(\d{0,4})?(\d+)?/ : /(\d{3})(\d{0,3})?(\d+)?/;
  return phoneNum.replace(regex, (match, p1, p2, p3) => {
    return `${p1}-${p2}-${p3}`;
  });
}

// 用户数据格式化（关注、粉丝、积分、获赞 ）
export function userDataFormat(num) {
  if (typeof num !== 'number') return '';
  if (num < 1000) return num + '';
  if (num >= 1000 && num < 1000000) return (num / 1000).toFixed(1) + 'K';
  if (num >= 1000000 && num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
  return (num / 1000000000).toFixed(1) + 'B';
}

/**
 * 获取元素距离浏览器顶部的距离
 */
export function getOffsetTop(el) {
  let top = el.offsetTop;
  el = el.offsetParent;
  while (el != null) {
    top += el.offsetTop;
    el = el.offsetParent;
  }
  return top;
}

/**
 * 获取浏览器可视区域尺寸
 * @param {Element} w 窗口
 */
export function getViewportSize(w) {
  //使用指定的窗口， 如果不带参数则使用当前窗口
  w = w || window;

  //除了IE8及更早的版本以外，其他浏览器都能用
  if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

  //对标准模式下的IE（或任意浏览器）
  var d = w.document;
  if (document.compatMode == 'CSS1Compat')
    return { w: d.documentElement.clientWidth, h: d.documentElement.clientHeight };

  //对怪异模式下的浏览器
  return { w: d.body.clientWidth, h: d.body.clientHeight };
}

/**
 * 检测滚动条是否滚动到页面底部
 * @param {Number} distance 距离浏览器底部的距离
 */
export function isScrollToPageBottom(distance = 500) {
  var documentHeight = document.documentElement.offsetHeight;
  var viewPortHeight = getViewportSize().h;
  var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  return documentHeight - viewPortHeight - scrollHeight < distance;
}

/**
 * 设置 HTML 标题 关键词 描述信息
 */
export function setHtmlMeta({ title, keywords, description }) {
  if (title) {
    document.title = title;
  }
  if (keywords) {
    document.querySelector('meta[name=keywords]').content = keywords;
  }
  if (description) {
    document.querySelector('meta[name=description]').content = description;
  }
}



/**
 * HTML特殊符号实体转换普通字符串（兼容 A7 历史数据）
 */
export function specialSymbol2String(str) {
  let div = document.createElement('div');
  div.innerHTML = str;
  const s = div.innerText || div.textContent;
  div = null;
  return s;
}

/**
 * 将字符串的手写字母转换为大写
 * @param {String} str 字符串
 * @returns 首字母大写的字符串
 */
export const firstLetterUpperCase = str => {
  str = str + '';
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};

/**
 * 根据毫秒数计算时间
 * @param {Number}} ms 毫秒
 * @returns 剩余时间对象
 */
export const getRemainingTime = ms => {
  let d, h, m, s;
  if (ms > 0) {
    d = Math.floor(ms / (1000 * 60 * 60 * 24)) + '';
    h = Math.floor((ms / (1000 * 60 * 60)) % 24) + '';
    m = Math.floor((ms / (1000 * 60)) % 60) + '';
    s = Math.floor((ms / 1000) % 60) + '';
  } else {
    d = '0';
    h = '0';
    m = '0';
    s = '0';
  }

  return { d, h, m, s, dd: d.padStart(2, '0'), hh: h.padStart(2, '0'), mm: m.padStart(2, '0'), ss: s.padStart(2, '0') };
};

/**
 * 从数组中随机获取指定数量的元素
 * @param arr 数组
 * @param n 元素个数
 * @returns 包含指定数量随机元素的数组
 */
export const getRandomItems = (arr, n = 1) => {
  let a = [...arr],
    m = arr.length;
  while (m) {
    const i = Math.floor(Math.random() * m--);
    [a[m], a[i]] = [a[i], a[m]];
  }
  return a.slice(0, n);
};

/**
 * 图片广告数据转换
 * @param {array} advs 广告列表
 * @param {string} system 系统（二级域名）
 * @param {number} zoomId 页面ID
 * @returns 广告数据
 */
export const bannerAdvConvert = (advs, system, zoomId, adType = 'banner') => {
  var  adv = {};
  var cache = {};
  /* const advCache = (advs || []).reduce((acc, curr) => {
    //const key = curr.showType == 3 ? curr['placement'] : curr['placement'] + '_' + curr['position'];
    const key = curr.showType == 3 ? curr['placement'] : curr['placement'] + '_command';
    if (acc[key]) {
      acc[key]['list'] = [...acc[key]['list'], { ...curr.data, width: curr.width, height: curr.height,speaceId:curr.id }];
    } else {
      acc[key] = { ...curr, list: [{ ...curr.data, width: curr.width, height: curr.height ,speaceId:curr.id}] };
      delete acc[key]['data'];
    }
    return acc;
  }, {}); */

  for(var i=0; i<advs.length;i++){
    var curr = advs[i];
    var key = curr['placement'];
    if( curr.showType == 3 ){
      if( adv[key]){
        adv[key]['list'] = [...adv[key]['list'], { ...curr.data, width: curr.width, height: curr.height,speaceId:curr.id }];
      }else{
        adv[key] = { ...curr, list: [{ ...curr.data, width: curr.width, height: curr.height ,speaceId:curr.id}] };
        delete adv[key]['data'];
      }
    }else{
      if(cache[key] == undefined ){
        cache[key] = [];
      }
      if( cache[key][curr['position']] == undefined ){
        cache[key][curr['position']] = []; 
      }  
      cache[key][curr['position']].push(curr);
    }
  }
 
  for(var key in cache){
    var data = cache[key].filter(e=>e!=undefined);
  
    for(var l=1; l<=data.length;l++){
      var dataList = data[l-1];
      var newKey = key+'_'+l;
      for(var index =0; index<dataList.length;index++){
        var curr = dataList[index];
        if(adv[newKey]){
          adv[newKey]['list'] = [...adv[newKey]['list'], { ...curr.data, width: curr.width, height: curr.height,speaceId:curr.id }];
        }else{
          adv[newKey] = { ...curr, list: [{ ...curr.data, width: curr.width, height: curr.height ,speaceId:curr.id}] };
          delete adv[newKey]['data'];
        }
      }      
    }
  }
  cache ={};

  const times = updateAdvTimes(adType, system, zoomId);

  return Object.keys(adv).reduce((acc, key) => {
    const curr = adv[key];
    acc[key] = { ...curr };
    if (curr.showType == 3) {
      acc[key]['list'] = [...curr.list];
    } else {
      acc[key] = { ...acc[key], ...curr.list[times % curr.list.length] };
      delete acc[key]['list'];
    }

    return acc;
  }, {});
};

/**
 * 信息流数据转换
 * @param {array} infos 信息流
 * @param {string} system 系统（二级域名）
 * @param {number} zoomId 页面ID
 * @returns 信息流数据
 */
export const inflowAdvConvert = (infos, system, zoomId, adType = 'inflow') => {
  if (!infos.some(info => info.app == 'crm_ad')) return infos;

  const times = updateAdvTimes(adType, system, zoomId);

  infos.forEach(info => {
    if (info.app == 'crm_ad') {
      info.material = info.material[times % info.material.length];
    }
  });

  return infos;
};

/**
 * 获取一级域名
 */
export const getTopHostname = (hostname = window.location.hostname) => {
  const m = hostname.match(/(\w+\.\w+)$/);
  return m ? m[0] : '';
};

/**
 * 是否是本站图片链接
 */
export const isMediaUrl = url =>
  new RegExp('^(https?:)?//media\\d?\\.(' + getTopHostname() + '|canada-cn\\.net|abc123\\.life|imgyb\\.xyz)').test(url);

/**
 * 移除本站图片样式
 */
export const removeMediaUrlSuffix = url => {
  const regex = /\/(webp\d+|watermark)$/;
  while (regex.test(url)) url = url.replace(regex, '');
  return url;
};

/**
 * 普通图片链接转换成webp链接
 */
export function webpFormat(url, size) {
  if (!url) return;
  if (typeof size != 'number') throw new Error(`webp size must be a number: ${url}, ${size}`);

  if (size <= 0 || size > 750) return url;

  size = Math.ceil(size / 50) * 50;

  if (isMediaUrl(url)) {
    url = removeMediaUrlSuffix(url);
    if (isSupportWebp) return `${url}/webp${size}`;
    if (!url.endsWith('.gif')) return `${url}/zoom${size}`;
  }
  return url;
}

/**
 * 普通图片链接转换成带水印链接
 */
export function watermarkFormat(url) {
  if (!url) return;

  if (isMediaUrl(url)) {
    url = removeMediaUrlSuffix(url);
    if (isSupportWebp) return `${url}/watermark`;
    if (!url.endsWith('.gif')) return `${url}/watermarkpng`;
  }
  return url;
}

/**
 * HTML字符串中的图片链接添加水印标记
 */
export const htmlStringImage2Watermark = (htmlStr, imageSelector = 'img') => {
  let oDiv = document.createElement('div');
  oDiv.innerHTML = htmlStr;
  [...oDiv.querySelectorAll(imageSelector)]
    .filter(el => !['spaceline', 'facial-expression'].includes(el.className))
    .forEach(el => {
      if (el.src && isMediaUrl(el.src)) {
        el.src = removeMediaUrlSuffix(el.src);
        if (isSupportWebp) {
          el.src = `${el.src}/watermark`;
        } else if (!el.src.endsWith('.gif')) {
          el.src = `${el.src}/watermarkpng`;
        }
      }
    });
  setTimeout(() => {
    oDiv = null;
  }, 0);
  return oDiv.innerHTML;
};

/**
 * 删除HTML字符串中的图片
 */
export const removeImageFromHtml = (htmlStr, imageSelector = 'img') => {
  let oDiv = document.createElement('div');
  oDiv.innerHTML = htmlStr;
  [...oDiv.querySelectorAll(imageSelector)].forEach(el => {
    el.parentNode.removeChild(el);
  });
  setTimeout(() => {
    oDiv = null;
  }, 0);
  return oDiv.innerHTML;
};

export const responseErrorFormat = (url, httpStatus, errorMsg) =>
  `<div style="line-height: 1.5;">
    <div>URL：${url}</div>
    <div>HTTP状态码：${httpStatus}</div>
    <div>当前时间：${new Date().toISOString()}</div>
    <div>服务器信息：${errorMsg}</div>
  </div>`;

export const writeGooleFormatData = (data)=>{
  let dom = document.createElement('script');
  dom.type ='application/ld+json';
  dom.className="format-data"
  dom.innerText = JSON.stringify(data);
  document.head.appendChild(dom);
}

export const writeOgTwitter = (data)=>{
  let tmp = '';
  tmp += `<meta class="og-twitter" property="og:type" content="article">`;
  tmp += `<meta class="og-twitter" property="og:title" content="${data.title}">`;
  tmp += `<meta class="og-twitter" property="og:description" content="${data.description}">`;
  tmp += `<meta class="og-twitter" property="og:url" content="${data.url}">`;
  tmp += `<meta class="og-twitter" property="og:image" content="${data.image}">`;
  tmp += `<meta class="og-twitter" property="og:site_name" content="约克论坛">`;
  tmp += `<meta class="og-twitter" property="twitter:card" content="summary_large_image">`;
  tmp += `<meta class="og-twitter" property="twitter:image" content="${data.image}">`;
  tmp += `<meta class="og-twitter" property="twitter:title" content="${data.title}">`;
  tmp += `<meta class="og-twitter" property="twitter:url" content="${data.url}">`;
  tmp += `<meta class="og-twitter" property="twitter:site" content="约克论坛">`;
  tmp += `<meta class="og-twitter" property="twitter:description" content="${data.description}">`;
  var fragment = document.createDocumentFragment();
  var dom = fragment.appendChild(document.createElement('div'))

  dom.innerHTML = tmp;
  var metaArr = dom.getElementsByTagName('meta');

  for(var i=0; i<metaArr.length;i++){
   
    document.head.appendChild(metaArr[i].cloneNode());
  }
  fragment = null;
}

export const linkNeedAddRel = (data)=>{
  var hostname = window.location.hostname;
  return data.startsWith('http://'+hostname) == false && data.startsWith('https://'+hostname) == false
}

export const ContentLinkAddRel = (data,relVal = 'ugc')=>{
  var fragment = document.createDocumentFragment();
  var tmp = fragment.appendChild(document.createElement('div'));
  tmp.innerHTML = data;
  var link = tmp.getElementsByTagName('a');
  var hostname = window.location.hostname;

  for(var i=0; i<link.length;i++){
    var linkHref = link[i].href;
    
    if( linkHref.startsWith('http://'+hostname) == false && linkHref.startsWith('https://'+hostname) == false ){
      link[i].setAttribute('rel',relVal)
    }
  }
  return tmp.innerHTML;
}

const appHashMap = {
  'info_post':{name:'黄页',host:'info'},
  'cms_post':{name:'新闻',host:'news'},
  'job_post':{name:'招聘',host:'job'},
  'bbs_post':{name:'论坛',host:'forum'},
  'forsale_post':{name:'二手',host:'forsale'},
  'car_post':{name:'汽车',host:'car'},
  'house_post':{name:'房产',host:'house'},
}
export const appName = (app)=>{
  return appHashMap[app].name;
}
export const idHostBuild= (id,app,topicEnName)=>{
  var host = appHashMap[app].host;
  var path = '';
  if( host == 'forum'){
    path = `/detail/${id}?topic=${topicEnName}`
  }else{
    path = `/detail/${topicEnName}/${id}`
  }
  return hostBuild(path,host);
}

export const htmlSubstring = (htmlStr,maxLength,onlyText = false)=>{
  let fragment = document.createDocumentFragment();
  let tmp = fragment.appendChild(document.createElement('div'));
  var iframeReg = /<(iframe)([^<]+)*>(.*)(<\/\1>|\s+\/)/g;
  var newHtmlStr = htmlStr.replace(iframeReg,'[视频]');
  tmp.innerHTML = newHtmlStr;
  function replaceImg(){
      let imgList = tmp.getElementsByTagName('img');
      if( imgList.length >0 ){
          let textDom;
          if(imgList[0].className.indexOf('facial-expression') != -1){
              textDom = document.createTextNode('[表情]');
          }else{
              textDom = document.createTextNode('[图片]');
          }
          
          imgList[0].parentElement.replaceChild(textDom,imgList[0]);
          replaceImg()
      }
  }
  replaceImg();
  return tmp.innerText.substring(0,maxLength);
}

export const showGoogleAd = ()=>{
  var dom = document.getElementsByClassName('yorkg-adsbygoogle');
  for(var i=0; i<dom.length;i++){
      var className = dom[i].classList;
     
      if(className.contains('loaded') == false){
          dom[i].classList.add('loaded');
          ( window.adsbygoogle || []).push({});
          break
      }
  }
}


export const infoTitleJoint = (cnName,enName,title)=>{
  let result = '';
  if( cnName.length>0 && enName.length>0){
    result += `${cnName}(${enName}) `
  }else if( cnName.length >0 ){
    result += cnName +' ';
  }else if( enName.length>0){
    result += enName+ ' ';
  }
  if( cnName != title){
    result += title;
  }
  return result;
}
