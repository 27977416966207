export class ExternalLink {
  constructor(transferURL = '/websafe') {
    // 中转的 URL
    this.transferURL = transferURL;

    // 一级域名
    const host = location.host.match(/([\w-]+\.[\w+]+)$/);

    // 放行的域名列表
    this.hosts = ['localhost', 'www.google.com'];

    if (host) {
      this.hosts.unshift(host[1]);
    }
  }

  /**
   * 监听页面跳转
   */
  addListener() {
    this._jumpHandler = this.jumpHandler.bind(this);
    document.addEventListener('click', this._jumpHandler);
  }

  /**
   * 解除监听页面跳转
   */
  removeListener() {
    document.removeEventListener('click', this._jumpHandler);
  }

  /**
   * 页面跳转处理
   */
  jumpHandler(e) {
    const nodeName = (e.target.nodeName || '').toLowerCase();
    const baseURL = (e.target.href || '').replace(/[?&].*$/, '');
    if (nodeName == 'a' && baseURL && !this.hosts.some(host => baseURL.indexOf(host) > 0)) {
      if (this.transferURL !== location.pathname) {
        window.open(`${this.transferURL}?url=${e.target.href}`, '_blank');
        e.preventDefault();
      }
    }
  }
}
