<template>
  <div class="qrcode-link">
    <button @click="onClick">
      <img src="./images/qrcode.png" />
    </button>
    <span class="qrcode-link__tip">扫码登录更安全</span>
  </div>
</template>
<script>
export default {
  name: 'QrcodeLink',
  props: {
    isDialog: Boolean,
  },
  methods: {
    onClick() {
      if (this.isDialog) {
        this.$emit('qrcodeLogin');
      } else {
        this.$router.push({ query: { ...this.$route.query, login_type: 'qrcode' } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qrcode-link {
  position: absolute;
  top: 0;
  right: 0;
}

.qrcode-link__tip {
  position: absolute;
  top: 10px;
  right: 48px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #ec9239;
  line-height: 26px;
  background: #ffffec;
  border: 1px solid #ffba76;
  padding: 0 8px;
  border-radius: 3px;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #ffffec;
    border-width: 1px 1px 0 0;
    border-color: #ffba76;
    border-style: solid;
    position: absolute;
    right: -5px;
    top: 9px;
    transform: rotate(45deg);
  }
}
</style>
