import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 获取多伦多时间
 * @param {string}} datetimeStr 日期时间字符串
 * @param {string} formatStr 解析格式
 * @returns 格式化后日期时间
 */
export const torontoTimeFormat = (datetimeStr, formatStr = 'YYYY-MM-DD HH:mm') =>
  dayjs(datetimeStr)
    .tz('America/Toronto')
    .format(formatStr);

    
/**
 * 本地时间转换成多伦多时间（本地时间2021年12月9号15时42分 转换后 多伦多时间2021年12月9号15时42分）
 */
export const localTimeToToronto = localTimeStr => {
  const d = dayjs(localTimeStr);
  return dayjs()
    .tz('America/Toronto')
    .year(d.year())
    .month(d.month())
    .date(d.date())
    .hour(d.hour())
    .minute(d.minute())
    .second(d.second())
    .millisecond(d.millisecond())
    .toISOString();
};

export const timeToGMT = (timeStramp) =>{
    
  return dayjs.tz(timeStramp,'America/Toronto').toISOString();
  }

export { dayjs };
