<template>
  <div class="login-phone">
    <YorkbbsQrcodeLink :isDialog="isDialog" v-on="$listeners"></YorkbbsQrcodeLink>
    <YorkbbsTabs :isDialog="isDialog" :type="type" v-on="$listeners"></YorkbbsTabs>

    <el-form ref="formRef" status-icon :model="form" :rules="formRules">
      <YorkbbsFormItemPhone
        prop="phone"
        placeholder="请输入手机号"
        :phone.sync="form.phone"
        :phonePrefix.sync="form.countryCode"
      ></YorkbbsFormItemPhone>
      <el-form-item prop="verifyCode">
        <div class="input-code-wrapper">
          <YorkbbsNumberInput
            clearable
            placeholder="请输入验证码"
            :maxlength="6"
            v-model.trim="form.verifyCode"
            @keyup.enter.native="phoneLogin"
          ></YorkbbsNumberInput>
          <el-button type="danger" plain disabled v-if="phoneCodeTimeVisible"
            >{{ phoneCodeTime }}秒后重新发送</el-button
          >
          <el-button
            type="danger"
            plain
            :disabled="phoneCodeButtonDisabled"
            :loading="phoneCodeLoading"
            v-else
            @click="getPhoneCode"
            >获取验证码</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          class="button-login"
          type="danger"
          :disabled="phoneButtonDisabled"
          :loading="phoneSubmitLoading"
          @click="phoneLogin"
          >登录</el-button
        >
      </el-form-item>
      <div class="regist-link__wrapper">
        <span>没有账户？</span>
        <a target="_blank" :href="'/regist' | hostBuild('account')">免费注册</a>
      </div>
    </el-form>
  </div>
</template>
<script>
import YorkbbsQrcodeLink from './qrcode-link.vue';
import YorkbbsTabs from './tabs.vue';
import YorkbbsFormItemPhone from '../form-item-phone.vue';
import YorkbbsNumberInput from '@/common/account/input-number.vue';
import { hostBuild } from '@/utils/utils';
import { debounce } from 'throttle-debounce';
const PHONE_CODE_TIME = 60;
const DEBOUNCE_TIME = 400;
export default {
  name: 'LoginPhone',
  props: {
    // 是否是对话框
    isDialog: Boolean,
    // 类型
    type: String,
  },
  components: { YorkbbsQrcodeLink, YorkbbsTabs, YorkbbsFormItemPhone, YorkbbsNumberInput },
  data() {
    return {
      // 手机验证登录表单
      form: {
        // 手机号码前缀
        countryCode: '+1',
        // 手机号码
        phone: '',
        // 验证码
        verifyCode: '',
      },

      // 手机验证登录表单校验规则
      formRules: {
        phone: [
          {
            validator: debounce(DEBOUNCE_TIME, false, this.validatePhone),
            trigger: 'change',
            // 是否已经验证通过 自定义字段
            success: false,
          },
        ],
        verifyCode: [
          {
            validator: debounce(DEBOUNCE_TIME, false, this.validateVerifyCode),
            trigger: 'change',
            // 是否已经验证通过 自定义字段
            success: false,
          },
        ],
      },

      // 手机验证登录加载状态
      phoneSubmitLoading: false,

      // 手机验证码倒计时显示状态
      phoneCodeTimeVisible: false,
      // 手机验证码倒计时剩余时长
      phoneCodeTime: PHONE_CODE_TIME,
      // 手机验证码按钮加载状态
      phoneCodeLoading: false,
      // 手机验证码计时器对象
      phoneCodeTimer: null,
    };
  },
  computed: {
    // 手机验证码按钮禁用状态
    phoneCodeButtonDisabled() {
      return !this.formRules.phone[0].success;
    },
    // 手机验证登录按钮禁用状态
    phoneButtonDisabled() {
      return !(this.formRules.phone[0].success && this.formRules.verifyCode[0].success);
    },
  },
  beforeDestroy() {
    clearInterval(this.phoneCodeTimer);
  },
  methods: {
    /**
     * 手机号验证器
     */
    async validatePhone(rule, value, callback) {
      this.formRules.phone[0].success = false;

      if (!/\d+/.test(value)) {
        return callback(new Error('格式不正确'));
      }

      if (this.form.countryCode == '+1' && value.length != 10) {
        return callback(new Error('格式不正确'));
      }

      if (this.form.countryCode == '+86' && value.length != 11) {
        return callback(new Error('格式不正确'));
      }

      const { data } = await this.$axios.post('/user/v1/regist/check/phone', {
        countryCode: this.form.countryCode,
        phone: this.form.phone,
      });
      if (data.result === 0) {
        return callback(new Error('该手机号未注册，请注册后登录'));
      }

      this.formRules.phone[0].success = true;
      callback();
    },
    /**
     * 验证码验证器
     */
    async validateVerifyCode(rule, value, callback) {
      this.formRules.verifyCode[0].success = false;

      if (value.length != 6) {
        return callback(new Error('验证码已过期或错误'));
      }

      this.formRules.verifyCode[0].success = true;
      callback();
    },
    /**
     * 手机验证登录
     */
    async phoneLogin() {
      this.phoneSubmitLoading = true;
      const { data } = await this.$axios
        .post('/user/v1/phone/login', {
          phone: this.form.phone,
          verifyCode: this.form.verifyCode,
        })
        .finally(() => {
          this.phoneSubmitLoading = false;
        });

      this.$refs.formRef.resetFields();

      const { token, user } = data;
      this.$store.dispatch('login', { token, user });

      this.$success('登录成功');

      if (this.isDialog) {
        return this.$emit('phoneLoginSuccess');
      }

      if (this.$route.query.redirect) {
        location.replace(this.$route.query.redirect);
      } else {
        location.replace(hostBuild('/', 'www'));
      }
    },
    /**
     * 获取手机验证码
     */
    async getPhoneCode() {
      // 发送验证码
      this.phoneCodeLoading = true;
      await this.$axios
        .post('/user/v1/send/phone/verify/code', {
          countryCode: this.form.countryCode,
          phone: this.form.phone,
          businessId: 1,
          sceneId: 2,
          checkRobot: false,
        })
        .finally(() => {
          this.phoneCodeLoading = false;
        });
      // 倒计时
      this.phoneCodeTimeVisible = true;
      this.phoneCodeTimer = setInterval(() => {
        if (this.phoneCodeTime <= 0) {
          clearInterval(this.phoneCodeTimer);
          this.phoneCodeTime = PHONE_CODE_TIME;
          this.phoneCodeTimeVisible = false;
        } else {
          this.phoneCodeTime--;
        }
      }, 1000);
      this.$success('验证码发送成功');
    },
  },
};
</script>
<style lang="scss" scoped>
.login-phone {
  background-color: #fff;
  border-radius: 6px;
  padding: 50px 70px 40px 60px;
  position: relative;

  // 重置输入框和按钮高度
  ::v-deep {
    .el-input__inner,
    .button-login {
      height: 48px;
      line-height: 48px;
    }
    .button-login {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 18px;
      width: 100%;
    }
    .el-input__inner {
      color: #111;
    }
    .el-input-group__prepend {
      .el-select {
        .el-input__inner {
          color: #111;
        }
      }
    }
  }

  // 重置表单项间的间距
  ::v-deep {
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-form-item {
      &:nth-child(3) {
        margin-bottom: 17px;
      }
    }
  }

  // 免费注册链接
  .regist-link__wrapper {
    font-size: 14px;
    line-height: 20px;
    color: #999;
    text-align: right;
    a {
      color: #e43a3a;
    }
  }

  // 验证中加载状态样式
  ::v-deep {
    .el-input__validateIcon {
      &.el-icon-circle-close,
      &.el-icon-circle-check {
        display: none;
      }
    }
    .el-input-group__prepend {
      .el-input__validateIcon {
        &.el-icon-loading {
          display: none;
        }
      }
    }
    .el-input__icon {
      width: 20px;
      line-height: 48px;
    }
  }

  // 带验证码输入框样式
  .input-code-wrapper {
    display: flex;
    justify-content: space-between;
    ::v-deep {
      .el-input {
        flex: 1;
        margin-right: 15px;
      }
      .el-button {
        width: 130px;
        padding-left: 0;
        padding-right: 0;
        font-weight: 400;
      }
    }
  }
}
</style>
