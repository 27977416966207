import Vue from 'vue';
import {
  timeFormat,
  timeToStr,
  numberFormat,
  distanceFormat,
  hostBuild,
  moneyFormat,
  titleFormat,
  phoneFormat,
  webpFormat,
  watermarkFormat,
  userDataFormat,
  idHostBuild,
  appName
} from './utils.js';

import { torontoTimeFormat } from './dayjs';

Vue.filter('timeFormat', timeFormat);
Vue.filter('timeToStr', timeToStr);
Vue.filter('numberFormat', numberFormat);
Vue.filter('distanceFormat', distanceFormat);
Vue.filter('hostBuild', hostBuild);
Vue.filter('moneyFormat', moneyFormat);
Vue.filter('titleFormat', titleFormat);
Vue.filter('phoneFormat', phoneFormat);
Vue.filter('webpFormat', webpFormat);
Vue.filter('watermarkFormat', watermarkFormat);
Vue.filter('userDataFormat', userDataFormat);
Vue.filter('torontoTimeFormat', torontoTimeFormat);
Vue.filter('idHostBuild', idHostBuild);
Vue.filter('appName', appName);

