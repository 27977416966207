import axios from 'axios';
import { cookies, deleteTokenFromCookie } from './cookies';
import { YorkbbsSign } from './signature.js';
import { errorMessage } from './element-ui';
import { responseErrorFormat } from './utils';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

/* const isProduction = process.env.NODE_ENV === 'production';
if( !isProduction){
  axios.defaults.proxy = {
    host: '192.168.3.88',
    port: '1080'
  };
} */


// 签名不通过，排查 NaN ，axios 会把 NaN 转换成 null
export function getAxios(appId, appKey) {
  
  let visitorId =  (async()=>{
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const resultId = await result.visitorId;
    
  
    return resultId;
  })(); 
  axios.interceptors.request.use(
    async config => {
      let uuid = await visitorId;
      if (config.method == 'get') {
        config.headers['uuid'] = uuid;
        config.headers['timestamp'] = Date.now();
        return config;
      };  
      /* 签名 */
      const { timestamp, nonceStr, sign } = new YorkbbsSign(appKey, { ...config.data},uuid).getSignData();

      const token = cookies.get('token');

      config.headers['timestamp'] = timestamp;
      config.headers['nonce-str'] = nonceStr;
      config.headers['sign'] = sign;
      config.headers['appid'] = appId;
      config.headers['client'] = 'pc';
      config.headers['token'] = token ? token : '';
      config.headers['uuid'] = uuid;
      config.headers['yorkrefree'] = document.referrer; 
      if(/^(\/media)/.test(config.url) ) return config;
      
      let sendData = {};
      if( config.data ){
        sendData = JSON.parse(JSON.stringify(config.data));
      }
      /* config.data['auth_info'] = {
        appid: appId,
        timestamp: timestamp,
        nonce_str: nonceStr,
        sign: sign,
        client: 'pc',
        token: token ? token : null,
      }; */
      /* sendData['auth_info'] = {
        appid: appId,
        timestamp: timestamp,
        nonce_str: nonceStr,
        sign: sign,
        client: 'pc',
        token: token ? token : null,
      }; */
      config.data = sendData;
      return config;
    },
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    res => {
      try{
  
        let url = res.config.url;
        let startTime = res.config.headers.timestamp;
        let endTime = Date.now();
        let userTime = endTime - startTime;
        window.dataLayer.push({
          'event':'api_loaded',
          'api_loading_ms':userTime>30000?30000:userTime,
          'api_path':url,
          'api_client':'pc',
          'api_appid':appId+'',
          'api_status_code':res.status+''
        })
      }catch(err){
        console.log(err);
      }
      const { code, msg } = res.data;
      if (code === -1) {
        errorMessage(msg);
        return Promise.reject(msg);
      } else if (code === -2) {
        errorMessage(msg);
        return Promise.reject(msg);
      } else if (code === 1) {
        // 需要重新登录
        deleteTokenFromCookie();
        return res.data;
      } else if (code === 2) {
        // 无权限
        errorMessage(msg);
        return res.data;
      }else if(code == -3){
        
        window.location.replace('/404?from='+encodeURIComponent(window.location.href));
        
        return Promise.reject(msg);
      } else {
        return res.data;
      }
    },
    err => {
      
      let e;
      // if (err.response.status == 400) {
      //   errorMessage('参数校验失败');
      //   throw new Error('参数校验失败');
      // }

      // if (err.response.status == 401) {
      //   errorMessage('权限错误');
      //   throw new Error('权限错误');
      // }

      // GateWay 超时
      try{
        let url = err.config.url;
        let startTime = err.config.headers.timestamp;
        let endTime = Date.now();
        let userTime = endTime - startTime;
        window.dataLayer.push({
          'event':'api_loaded',
          'api_loading_ms':userTime>30000?30000:userTime,
          'api_path':url,
          'api_client':'pc',
          'api_appid':appId+'',
          'api_status_code':err.response.status+''
        })
      }catch(err){
        console.log('ga错误--------》',err);
      }

      if (err.response.status == 504) {
        errorMessage((e = responseErrorFormat(err.config.url, err.response.status, err.response.statusText)));
      } else if (err.response.data.msg) {
        errorMessage((e = responseErrorFormat(err.config.url, err.response.status, err.response.data.msg)));
      } else {
        errorMessage((e = responseErrorFormat(err.config.url, err.response.status, err.response.data)));
      }

      throw new Error(e);
    },
  );

  return axios;
}
