<template>
  <div class="account-login">
    <YorkbbsQrcodeLink :isDialog="isDialog" v-on="$listeners"></YorkbbsQrcodeLink>
    <YorkbbsTabs :isDialog="isDialog" :type="type" v-on="$listeners"></YorkbbsTabs>
    <el-form ref="formRef" status-icon :model="form" :rules="formRules">
      <el-form-item prop="loginCode">
        <el-input clearable v-model.trim="form.loginCode" :maxlength="50" placeholder="用户名/ Email"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <YorkbbsPasswordInput
          class="input-password"
          clearable
          v-model.trim="form.password"
          placeholder="密码"
          @keyup.enter.native="accountLogin"
        >
          <i class="el-icon-camera"></i>
          <template slot="append">
            <a target="_blank" :href="'/reset' | hostBuild('account')">忘记密码</a>
          </template>
        </YorkbbsPasswordInput>
      </el-form-item>
      <el-form-item>
        <el-button
          class="button-login"
          type="danger"
          :loading="submitLoading"
          :disabled="submitButtonDisabled"
          @click="accountLogin"
          >登录</el-button
        >
      </el-form-item>
      <div class="regist-link__wrapper">
        <span>没有账户？</span>
        <a target="_blank" :href="'/regist' | hostBuild('account')">免费注册</a>
      </div>
    </el-form>
  </div>
</template>
<script>
import YorkbbsQrcodeLink from './qrcode-link.vue';
import YorkbbsTabs from './tabs.vue';
import YorkbbsPasswordInput from '../input-password.vue';
import { debounce } from 'throttle-debounce';
import { hostBuild } from '@/utils/utils';
const DEBOUNCE_TIME = 400;
export default {
  name: 'LoginAccount',
  components: { YorkbbsQrcodeLink, YorkbbsTabs, YorkbbsPasswordInput },
  props: {
    // 是否是对话框
    isDialog: Boolean,
    // 类型
    type: String,
  },
  data() {
    return {
      // 账号密码表单
      form: {
        // 账号
        loginCode: '',
        // 密码
        password: '',
      },

      // 账号密码表单校验规则
      formRules: {
        loginCode: [
          {
            validator: debounce(DEBOUNCE_TIME, false, this.validateLoginCode),
            trigger: 'change',
            // 是否已经验证通过 自定义字段
            success: false,
          },
        ],
        password: [
          {
            validator: debounce(DEBOUNCE_TIME, false, this.validatePassword),
            trigger: 'change',
            // 是否已经验证通过 自定义字段
            success: false,
          },
        ],
      },

      // 账号密码登录加载状态
      submitLoading: false,
    };
  },
  computed: {
    // 账号登录按钮禁用状态
    submitButtonDisabled() {
      return !(this.formRules.loginCode[0].success && this.formRules.password[0].success);
    },
  },
  methods: {
    /**
     * 用户名邮箱验证器
     */
    validateLoginCode(rule, value, callback) {
      this.formRules.loginCode[0].success = false;
      if (value.length == 0) {
        return callback(new Error('用户名或 Email 不能为空'));
      }

      this.formRules.loginCode[0].success = true;
      callback();
    },
    /**
     * 密码验证器
     */
    validatePassword(rule, value, callback) {
      this.formRules.password[0].success = false;
      if (value.length == 0) {
        return callback(new Error('密码不能为空'));
      }

      this.formRules.password[0].success = true;
      callback();
    },
    /**
     * 账号密码登录
     */
    async accountLogin() {
      this.submitLoading = true;
      const { data } = await this.$axios
        .post('/user/v1/account/login', {
          loginCode: this.form.loginCode,
          password: this.$md5(this.form.password),
        })
        .finally(() => {
          this.submitLoading = false;
        });

      this.$refs.formRef.resetFields();

      const { token, user } = data;
      this.$store.dispatch('login', { token, user });

      this.$success('登录成功');

      if (this.isDialog) {
        return this.$emit('accountLoginSuccess');
      }

      if (this.$route.query.redirect) {
        location.replace(this.$route.query.redirect);
      } else {
        location.replace(hostBuild('/', 'www'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account-login {
  background-color: #fff;
  border-radius: 6px;
  padding: 50px 70px 40px 60px;
  position: relative;

  // 重置输入框和按钮高度
  ::v-deep {
    .el-input__inner,
    .button-login {
      height: 48px;
      line-height: 48px;
    }
    .button-login {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 18px;
      width: 100%;
    }
    .el-input__inner {
      color: #111;
    }
    .el-input-group__prepend {
      .el-select {
        .el-input__inner {
          color: #111;
        }
      }
    }
  }

  // 重置表单项间的间距
  ::v-deep {
    .el-form-item {
      margin-bottom: 30px;
      &:nth-child(3) {
        margin-bottom: 17px;
      }
    }
  }

  // 免费注册链接
  .regist-link__wrapper {
    font-size: 14px;
    line-height: 20px;
    color: #999;
    text-align: right;
    a {
      color: #e43a3a;
    }
  }

  // 密码输入框样式
  ::v-deep {
    .input-password {
      > .el-input__inner {
        border-right-width: 0;
      }
      .el-input-group__append {
        background-color: #fff;
        padding-left: 10px;
        padding-right: 14px;
        &::after {
          content: '';
          width: 1px;
          height: 50%;
          background-color: #dcdfe6;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .el-form-item {
      .el-input-group {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        .el-input-group__append {
          border: 0 none;
        }
        .el-input__inner {
          border: 0 none;
        }
      }
      &.is-error {
        .el-input-group {
          border-color: #e43a3a;
        }
      }
      &:not(.is-error) {
        .el-input-group {
          &:active,
          &:focus,
          &:hover {
            border-color: #c0c4cc;
          }
        }
      }
    }
  }

  // 验证中加载状态样式
  ::v-deep {
    .el-input__validateIcon {
      &.el-icon-circle-close,
      &.el-icon-circle-check {
        display: none;
      }
    }
    .el-input-group__prepend {
      .el-input__validateIcon {
        &.el-icon-loading {
          display: none;
        }
      }
    }
    .el-input__icon {
      width: 20px;
      line-height: 48px;
    }
  }
}
</style>
