import { render, staticRenderFns } from "./alert-mobile.vue?vue&type=template&id=101f7fe1&scoped=true"
import script from "./alert-mobile.vue?vue&type=script&lang=js"
export * from "./alert-mobile.vue?vue&type=script&lang=js"
import style0 from "./alert-mobile.vue?vue&type=style&index=0&id=101f7fe1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101f7fe1",
  null
  
)

export default component.exports