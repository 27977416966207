<template>
  <!-- 这个地方不能使用 v-on="$listeners" -->
  <el-input
    :value="value"
    v-bind="$attrs"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
    @input="onInput"
    @clear="onClear"
  ></el-input>
</template>
<script>
export default {
  name: 'InputNumber',
  props: {
    value: String,
  },
  methods: {
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onChange(val) {
      this.$emit('change', val);
    },
    onInput(val) {
      this.$emit('input', val.replace(/[^\d\s]/g, ''));
    },
    onClear() {
      this.$emit('clear');
    },
  },
};
</script>
